var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { lg: "12" } },
        [
          _c(
            "CCard",
            {
              staticClass: "card-border ",
              attrs: { "accent-color": _vm.accentColor }
            },
            [
              _c(
                "CCardHeader",
                {
                  staticClass: "card-header",
                  staticStyle: { color: "var(--light)" },
                  attrs: { color: _vm.theme }
                },
                [
                  _c(
                    "div",
                    [
                      _vm._t("header", [
                        _c("CIcon", { attrs: { name: _vm.icon } }),
                        _vm._v(" " + _vm._s(_vm.title) + " ")
                      ])
                    ],
                    2
                  )
                ]
              ),
              _c(
                "CCardBody",
                { staticClass: "layout-card-body" },
                [_vm._t("default")],
                2
              ),
              _c("CCardFooter", [_vm._t("footer")], 2)
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CElementCover",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.cover,
              expression: "cover"
            }
          ],
          attrs: {
            boundaries: [{ sides: ["top", "left"], query: ".card-body" }],
            opacity: _vm.coverOpacity
          }
        },
        [
          _vm._t("cover", [
            _c("h1", { staticClass: "d-inline" }, [
              _vm._v(_vm._s(_vm.coverMessage) + " ")
            ]),
            _vm.variant === "kebab"
              ? _c("CKebabSpinner")
              : _c("CSpinner", { attrs: { size: "6xl", color: "info" } })
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }