<template>
    <span>
        <CSpinner color="info" grow/> 
        <CSpinner color="info" grow/>
        <CSpinner color="info" grow/> 
        </span>
</template>


<script>
export default {
    name:"CKebabSpinner", 
    props:{
        color: {
            type: String, 
            default: "info"
        }
       
    }
}
</script>
