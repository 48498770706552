<template>
    <CRow>
    <CCol lg="12">
      <CCard :accent-color="accentColor" class="card-border ">
        <CCardHeader :color="theme" style="color: var(--light)" class="card-header">
            <div>
            <slot name="header">
                <CIcon :name="icon" /> {{title}}
          </slot> 
          </div>
        </CCardHeader>
        <CCardBody class="layout-card-body">
          <slot /> 
        </CCardBody>
        <CCardFooter> 
            <slot name="footer"/>
        </CCardFooter>
      </CCard>
    </CCol>
    <CElementCover
      v-show="cover"
      :boundaries="[{ sides: ['top', 'left', ], query: '.card-body' }]"
      :opacity="coverOpacity"
    >
    <slot name="cover">
        
      <h1 class="d-inline">{{coverMessage}} </h1>

      <CKebabSpinner v-if="variant==='kebab'"></CKebabSpinner>
      <CSpinner v-else size="6xl" color="info" />
      </slot>
    </CElementCover>
    </CRow>
    
</template>

<style scoped>
.card-border {
    border-radius: 5px;
}

.layout-card-body{

}

.card-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
</style>


<script>


/** Defines a Default Layout that can be re-used for Pages 
 * To be Rendered in Router View
 */
// TODO: Import the thememable mixin here and we will make layout themeable

//import ThemableCardMixin from "@/components/mixins/ThemableCardMixin"; 
import CKebabSpinner from '@/components/base/CKebabSpinner';

const DEFAULT_LOADING_TEXT=""; // TODO: Load from App config 
const DEFAULT_SPINNER_TYPE="kebab"; 

export default {
    name:"CardLayout", 
    components:{
        CKebabSpinner
    }, 
    props:{
        accentColor:{
            type: String,
            default: "danger"
        },
        title:{
            type: String, 
            default: "",
        },
        /** Define which CoreUI Icon to show */
        icon: {
            type: String, 
            default: "cil-file"
        },
        cover: { 
            /**Defines if Element Cover is Shown or not  */
            type: Boolean, 
            default: false, 
        }, 
        coverOpacity:{
            type: Number, 
            default: 0.1
        },
        /** Override default message when element cover is shown
         * Note: You can also override this with the #cover slot 
         */
        coverMessage:{
            type: String,
            default: ()=>DEFAULT_LOADING_TEXT//"Loading..." // Left intentionally Blank for Release 
        }, 
        variant:{
            type: String, 
            default:  DEFAULT_SPINNER_TYPE, //"kebab",
        }

    },  
    data(){
        return{

        }
    },
    computed:{
        theme(){
            if(this.$theme && this.$theme.backColor){
                return this.$theme.backColor;
            }
            return "";
        }
    }, 
    created(){
        
    }
}
</script>